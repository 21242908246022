<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    imagePosition?: 'left' | 'right';
    imagePath?: string;
    customClasses?: string;
  }>(),
  {
    imagePosition: 'left',
    imagePath: '',
    customClasses: '',
  },
);

const variantClasses = computed(() => {
  return twMerge(
    cva('testimonial scale-75 opacity-0 bg-white w-fit h-fit py-1 none md:flex rounded-[15px] items-center justify-between gap-1', {
      variants: {
        imagePosition: {
          left: 'pr-3 pl-1',
          right: 'pr-1 pl-3 flex-row-reverse',
        },
      },
    })({
      imagePosition: props.imagePosition,
    }),
    props.customClasses,
  );
});
</script>

<template>
  <div :class="variantClasses">
    <div class="h-6 w-6">
      <NuxtImg role="presentation" :src="imagePath" class="rounded-full shrink-0 object-cover block h-full w-full" />
    </div>
    <p class="text-sm">"<slot name="testimonial" />"</p>
  </div>
</template>
