<script lang="ts" setup>
import LandingTestimonial from '~/pages/plan/index/components/LandingTestimonial.vue';

const { $gsap } = useNuxtApp();
const animate = () => {
  const matchMedia = $gsap.matchMedia();
  const firstFoldTimeline = $gsap.timeline();

  const firstfoldSelector = $gsap.utils.selector('.first-fold');

  firstFoldTimeline
    // Heading and subtitle animation
    .fromTo(firstfoldSelector('.title'), { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 1 }, 'heading')
    .fromTo(firstfoldSelector('.subtitle'), { opacity: 0, y: -10 }, { opacity: 1, y: 0, duration: 1 }, 'heading')
    // Circle animations
    .fromTo(
      firstfoldSelector('.circle-1'),
      { scale: 0.75, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        duration: 1.2,
      },
      '<0.1',
    )
    .fromTo(
      firstfoldSelector('.circle-2'),
      { scale: 0.5, opacity: 0 },
      {
        scale: 0.75,
        opacity: 1,
        duration: 1.2,
      },
      '<0.5',
    )
    // Image animations
    .fromTo(
      firstfoldSelector('.image-1'),
      { scale: 0.9, opacity: 0, rotate: 0 },
      { scale: 1.1, opacity: 1, rotate: -12, transformOrigin: 'bottom right', duration: 1 },
      '<0.5',
    )
    .fromTo(
      firstfoldSelector('.image-2'),
      { scale: 0.9, opacity: 0, rotate: 0 },
      { scale: 1.1, opacity: 1, rotate: 12, transformOrigin: 'bottom left', duration: 1 },
      '<0.3',
    )
    .fromTo(
      firstfoldSelector('.free-cta'),
      { opacity: 0 },
      {
        opacity: 1,
        duration: 1.5,
      },
      '<0.3',
    );
  matchMedia.add('(min-width: 800px)', () => {
    firstFoldTimeline // Testimonial animation with stagger
      .fromTo(
        firstfoldSelector('.testimonial'),
        { scale: 0.75, opacity: 0 },
        {
          scale: 1,
          opacity: 1,
          duration: 0.8,
          ease: 'elastic.out(1.5, 0.3)',
          stagger: { amount: 0.8, from: 'random' },
        },
        '<',
      );
  });
};

onMounted(() => {
  animate();
});

onUnmounted(() => {
  $gsap.killTweensOf('.first-fold *'); // Optimized cleanup
});
</script>

<template>
  <section class="first-fold grid grid-cols-4 px-4 pb-12 md:pb-20 lg:pb-24 max-w-screen-2xl mx-auto mt-8 overflow-hidden h-full md:h-auto">
    <div class="col-span-full row-start-1 row-end-2 text-center pb-12 md:pb-0 z-50">
      <UIHeading customClasses="title mb-3 leading-none opacity-0 translate-y-3" size="h1" styleSize="h1">
        {{ $t('landing.yourFutureYourWay') }}
      </UIHeading>
      <p class="subtitle opacity-0 -translate-y-5 max-w-[600px] lg:max-w-full mx-auto">
        {{ $t('landing.getAdviceAndSupport') }}
      </p>
    </div>
    <div
      class="circle-1 opacity-0 scale-75 col-start-1 col-end-5 lg:col-start-2 lg:col-end-4 row-start-2 row-end-5 justify-self-center self-center h-auto w-full md:w-[650px] aspect-square border border-white border-dashed rounded-full lg:-translate-y-7"
    />
    <div
      class="circle-2 opacity-0 scale-50 col-start-1 col-end-5 lg:col-start-2 lg:col-end-4 row-start-2 row-end-5 justify-self-center self-center h-auto w-full md:w-[650px] aspect-square border border-white border-dashed rounded-full lg:-translate-y-7"
    />
    <UIButton
      :text="$t('landing.getStartedNowItsFree')"
      :to="{ name: 'plan-login' }"
      class="free-cta col-start-1 col-end-5 lg:col-start-2 lg:col-end-4 row-start-2 row-end-5 justify-self-center self-center z-10 w-fit opacity-0"
      customClasses="text-lg md:text-3xl hover:scale-110 transition-transform duration-500 shadow-xl"
      iconPath="circle-right-arrow"
      iconPosition="right"
      size="lg"
      variant="gradient"
    />
    <NuxtImg
      alt="Academic plan"
      class="image-1 scale-90 opacity-0 col-start-2 col-end-4 row-start-2 row-end-5 justify-self-center self-center h-auto w-[165px] md:w-[210px] -translate-x-10 md:-translate-y-14"
      preload
      src="/images/landing/academic-plan-phone.webp"
    />
    <NuxtImg
      alt="Vision board"
      class="image-2 scale-90 opacity-0 col-start-2 col-end-4 row-start-2 row-end-5 justify-self-center self-center h-auto w-[165px] md:w-[210px] translate-x-10 translate-y-5"
      preload
      src="/images/landing/cvb-phone.webp"
    />
    <LandingTestimonial
      class="testimonial-1 col-start-1 row-start-2 self-center translate-x-12 justify-self-end"
      imagePath="/images/landing/quote-avatar/quote-avatar1.webp"
    >
      <template #testimonial>{{ $t('landing.testimonial.finance') }}</template>
    </LandingTestimonial>
    <LandingTestimonial
      class="testimonial-2 col-start-1 row-start-3 self-center translate-x-6 justify-self-end"
      imagePath="/images/landing/quote-avatar/quote-avatar2.webp"
    >
      <template #testimonial>{{ $t('landing.testimonial.whatToStudy') }}</template>
    </LandingTestimonial>
    <LandingTestimonial
      class="testimonial-3 col-start-1 row-start-4s self-center translate-x-20 justify-self-end"
      imagePath="/images/landing/quote-avatar/quote-avatar3.webp"
      imagePosition="right"
    >
      <template #testimonial>{{ $t('landing.testimonial.wantToSing') }}</template>
    </LandingTestimonial>
    <LandingTestimonial
      class="testimonial-4 col-start-4 row-start-2 self-center -translate-x-12 justify-self-start"
      imagePath="/images/landing/quote-avatar/quote-avatar4.webp"
    >
      <template #testimonial>{{ $t('landing.testimonial.whichCollegeToGo') }}</template>
    </LandingTestimonial>
    <LandingTestimonial
      class="testimonial-5 col-start-4 row-start-3 self-start translate-y-4 justify-self-start"
      imagePath="/images/landing/quote-avatar/quote-avatar5.webp"
    >
      <template #testimonial>{{ $t('landing.testimonial.rightCourseForMe') }}</template>
    </LandingTestimonial>
    <LandingTestimonial
      class="testimonial-6 col-start-4 row-start-3 self-end -translate-x-5 -translate-y-4 justify-self-start"
      imagePath="/images/landing/quote-avatar/quote-avatar6.webp"
      imagePosition="right"
    >
      <template #testimonial>{{ $t('landing.testimonial.leaveSchool') }}</template>
    </LandingTestimonial>
    <LandingTestimonial
      class="testimonial-7 col-start-4 row-start-4 self-center -translate-x-20 justify-self-start"
      imagePath="/images/landing/quote-avatar/quote-avatar7.webp"
      imagePosition="right"
    >
      <template #testimonial>{{ $t('landing.testimonial.anxiousAboutFuture') }}</template>
    </LandingTestimonial>
  </section>
</template>

<style scoped>
.testimonial {
  background: rgba(255, 255, 255, 0.66);
  box-shadow:
    0 8px 24px 0px rgba(2, 0, 98, 0.1),
    0 2px 3.4px 0px rgba(255, 255, 255, 0.6) inset;
  backdrop-filter: blur(16px);
}
</style>
