<script lang="ts" setup>
const { $gsap } = useNuxtApp();
const animate = () => {
  const matchMedia = $gsap.matchMedia();

  const firstCtaSelector = $gsap.utils.selector('.first-cta');
  const aiAssistantSelector = $gsap.utils.selector('.ai-assistant');

  const firstCtaTimeline = $gsap.timeline({
    scrollTrigger: {
      trigger: '.first-cta',
      start: 'top 80%',
      once: true,
    },
  });

  // Initial animations for '.first-cta'
  firstCtaTimeline.fromTo(
    firstCtaSelector('.cta-content'),
    { opacity: 0, x: '-1.5rem' },
    {
      opacity: 1,
      x: 0,
      duration: 1,
    },
  );

  // Desktop view (min-width: 1024px)
  matchMedia.add('(min-width: 1024px)', () => {
    firstCtaTimeline.fromTo(
      firstCtaSelector('.ui-assistant'),
      { opacity: 0, rotate: 360, scale: 0.75 },
      { opacity: 1, rotate: 0, scale: 0.85, duration: 0.7 },
      '<0.3',
    );
    firstCtaTimeline.fromTo(firstCtaSelector('.prompt-1'), { opacity: 0 }, { opacity: 1, duration: 0.5 });
    firstCtaTimeline.to(
      firstCtaSelector('.ui-assistant'),
      {
        scale: 1,
        duration: 0.5,
        repeat: 2,
        yoyo: true,
        ease: 'power1.inOut',
      },
      '<0.3',
    );
    firstCtaTimeline.fromTo(firstCtaSelector('.prompt-2'), { opacity: 0 }, { opacity: 1, duration: 1 }, '<0.2');
  });

  // Mobile view (max-width: 1023px)
  matchMedia.add('(max-width: 1023px)', () => {
    const aiAssistantTimeline = $gsap.timeline({
      scrollTrigger: {
        trigger: '.ai-assistant',
        start: 'top 80%',
        once: true,
      },
    });
    aiAssistantTimeline.fromTo(
      aiAssistantSelector('.ui-assistant'),
      { opacity: 0, rotate: 360, scale: 0.75 },
      { opacity: 1, rotate: 0, scale: 0.85, duration: 0.7 },
      '<0.3',
    );
    aiAssistantTimeline.fromTo(aiAssistantSelector('.prompt-1'), { opacity: 0 }, { opacity: 1, duration: 0.5 });
    aiAssistantTimeline.to(
      aiAssistantSelector('.ui-assistant'),
      { opacity: 1, scale: 1, duration: 0.5, repeat: 2, yoyo: true, ease: 'power1.inOut' },
      '<0.3',
    );
    aiAssistantTimeline.fromTo(aiAssistantSelector('.prompt-2'), { opacity: 0 }, { opacity: 1, duration: 1 }, '<0.2');
  });
};

onMounted(() => {
  animate();
});

onUnmounted(() => {
  $gsap.killTweensOf('.first-cta *'); // Optimized cleanup
});
</script>

<template>
  <div class="first-cta container flex flex-col lg:flex-row gap-16 justify-center lg:justify-between align-center py-12 lg:py-24 px-4">
    <div class="cta-content flex flex-col justify-center self-center gap-8 max-w-sm text-center lg:text-left opacity-0 -translate-x-6">
      <UIHeading :htmlText="$t('landing.getAdviceBasedOnGoals')" size="h2" styleSize="h2" />
      <p>{{ $t('landing.withYourAiCoach') }}</p>
      <UIButton :text="$t('landing.getStartedItsFree')" :to="{ name: 'plan-login' }" customClasses="w-fit sm:w-fit mx-auto lg:mx-0" />
    </div>
    <div class="ai-assistant grid grid-cols-[150px_1fr] lg:grid-cols-[195px_minmax(450px, 1fr)] grid-rows-2 max-w-2xl mx-auto lg:mx-0 mt-12 md:mt-0">
      <div class="col-start-1 col-end-2 row-start-1 row-end-2 w-48">
        <UIAssistantImage assistantType="SAPPHIRE" customClasses="opacity-0 ui-assistant scale-75 rotate-360 h-full w-full block" size="lg" />
      </div>
      <div
        class="prompt-1 opacity-0 col-start-2 col-span-1 md:col-start-2 md:col-span-1 row-start-1 row-end-2 self-start md:self-center shadow-box w-md overflow-hidden rounded-l-2xl rounded-br-2xl border border-[#e7e7e7] -translate-y-8"
      >
        <div class="question px-4 py-4 text-secondary-500 font-semibold text-base md:text-lg">
          {{ $t('landing.whyMarineBiologistQuestion') }}
        </div>
      </div>
      <div
        class="prompt-2 opacity-0 col-start-1 col-span-2 md:col-start-2 md:col-span-1 row-start-2 row-end-3 self-start shadow-box max-w-sm overflow-hidden rounded-lb-2xl rounded-r-2xl border border-[#e7e7e7] -translate-y-8 md:-translate-y-16 md:-translate-x-20 max-w-96"
      >
        <div class="answer flex gap-2 px-4 py-4">
          <UIAssetIcon class="shrink-0" path="sparkle" size="md" />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <p class="text-base md:text-lg" v-html="$t('landing.whyMarineBiologistAnswer')" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shadow-box {
  box-shadow: -5px 10px 25px 0px #0200621f;
  border: 1px solid #e7e7e7;
}

.question,
.answer {
  box-shadow: 0 1.6px 3.2px 0px #0000000a inset;
  background: #ffffff7a;
}
</style>
