<script lang="ts" setup>
import { useIntersectionObserver, useWindowSize } from '@vueuse/core';
import FirstFold from '~/pages/plan/index/components/FirstFold.vue';

const { t } = useI18n();

definePageMeta({
  authType: [AUTH_ROUTE_TYPE_GUEST, AUTH_ROUTE_TYPE_GHOST],
});

useHead({
  title: t('general.plan.landingPageTitle'),
  meta: [
    {
      name: 'title',
      content: t('general.plan.landingPageTitle'),
    },
    {
      name: 'keywords',
      content: t('general.plan.keywords'),
    },
  ],
});

const analytics = useAnalyticsService();
onMounted(() => {
  analytics.track(EVENTS.LANDING_PAGE_VIEWED);
});

/* change logo depending on mobile or desktop */
const headerEl = ref<HTMLElement | null>(null);

const { width } = useWindowSize();
const logoVariant = computed(() => {
  return width.value > 475 ? 'lockupPrimaryLight' : 'iconmarkDark';
});

const { $gsap } = useNuxtApp();
const backToTop = () => {
  $gsap.to(window, { duration: 2, scrollTo: 0 });
};

const visibleSections = ref(['firstCta']);
const firstCta = ref<HTMLElement | null>(null);
useIntersectionObserver(firstCta, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('visualize')) {
    visibleSections.value.push('visualize');
  }
});

const visualize = ref<HTMLElement | null>(null);
useIntersectionObserver(visualize, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('tracking')) {
    visibleSections.value.push('tracking');
  }
});

const tracking = ref<HTMLElement | null>(null);
useIntersectionObserver(tracking, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('feelConfident')) {
    visibleSections.value.push('feelConfident');
  }
});

const feelConfident = ref<HTMLElement | null>(null);
useIntersectionObserver(feelConfident, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('unnoticedSkills')) {
    visibleSections.value.push('unnoticedSkills');
  }
});

const unnoticedSkills = ref<HTMLElement | null>(null);
useIntersectionObserver(unnoticedSkills, ([{ isIntersecting }]) => {
  if (isIntersecting && !visibleSections.value.includes('footer')) {
    visibleSections.value.push('footer');
  }
});

const show = (item: string) => {
  return visibleSections.value.includes(item);
};
</script>

<template>
  <div id="landing-page" class="overflow-x-hidden overflow-y-auto">
    <div class="splash bg-[url('/images/landing/gradients/splash.svg')] bg-top bg-cover">
      <header ref="headerEl" class="container flex justify-between py-4 px-4">
        <CoreClusterLogo :key="logoVariant" :variant="logoVariant" />
        <div class="flex items-center gap-4 md:gap-8">
          <UIButton size="sm" text="Login" to="/login" variant="no-style" />
          <UIButton size="sm" text="Signup" to="/login" />
        </div>
      </header>
      <FirstFold />
    </div>
    <div v-if="show('firstCta')" ref="firstCta" class="bg-[url('/images/ai-panel.webp')] bg-no-repeat bg-cover bg-top">
      <FirstCta />
    </div>
    <div ref="visualize">
      <LazyVisualize v-if="show('visualize')" />
    </div>
    <div ref="tracking">
      <LazyTracking v-if="show('tracking')" />
    </div>
    <div ref="feelConfident">
      <LazyFeelConfident v-if="show('feelConfident')" />
    </div>
    <div ref="unnoticedSkills">
      <LazyUnnoticedSkills v-if="show('unnoticedSkills')" />
    </div>
    <div class="pb-12 text-center">
      <UIButton :text="$t('landing.backToTop')" variant="no-style" @click="backToTop" />
    </div>
    <div ref="footer">
      <LazyUIFooter v-if="show('footer')" />
    </div>
  </div>
</template>
